import React, { PropsWithChildren } from "react";

interface IProps {
    onClose: () => void;
}

export const CloseButton = (props: PropsWithChildren<IProps>) => {
    const { onClose } = props;

    return (
        <a className="close" onClick={onClose}>
            &times;
        </a>
    );
};
