import React from "react";

export const Overlay = () => {
    return (
        <div>
            <div id="bernard" className="character" />
            <div id="kerry" className="character" />
        </div>
    );
};
