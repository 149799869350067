import React, { PropsWithChildren } from "react";
import { CloseButton } from "./CloseButton";

interface IProps {
    closeTerms: () => void;
}

export const TermsAndConditions = (props: PropsWithChildren<IProps>) => {
    const { closeTerms } = props;

    return (
        <div className="popUp">
            <CloseButton onClose={closeTerms} />
            <h2>Terms &amp; Conditions</h2>
            <div className="text">
                <h3>
                    LIKE A PHOTON CREATIVE PTY LTD (ACN 159 756 279)
                    <br />
                    The Wishmas Tree Ticket Competition
                    <br />
                    Terms and Conditions
                </h3>
                <p>
                    The Tales From Sanctuary City Hub (Hub) accessible at: visitsanctuarycity.com is owned and operated
                    by Like A Photon Creative Pty Ltd ACN 159 756 279 (Promoter, we, us, our). The Promoter is running a
                    ticket lottery related to cinema tickets for our film, The Wishmas Tree, being a competition of
                    chance (Competition).
                </p>
                <p>
                    Your entry and participation in the Competition is conditional upon your agreement to, acceptance of
                    and compliance with the Terms and Conditions set out below (Terms and Conditions). Your submission
                    of an entry into the Competition constitutes your agreement with these Terms and Conditions.
                </p>

                <h3>TERMS AND CONDITIONS</h3>

                <p>1. Relevant States and Territories</p>
                <p>
                    1.1Entry is only open to residents of the Australian states and territories of the Australian
                    Capital Territory, New South Wales, Northern Territory, Queensland, Victoria, South Australia,
                    Western Australia and Tasmania, who comply with these Terms and Conditions.
                </p>
                <p>2. Competition Period</p>
                <p>
                    The Competition commences at 00:01 AEST/AEDT on 13 February 2020 (Commencement Date) and closes at
                    26 March 2020 (End Date). The period between the Commencement Date and End Date is the Competition
                    Period.
                </p>
                <p>
                    The Competition will be conducted during the Competition Period. To enter the Competition,
                    Participants must follow the Entry Methods and Restrictions as set out in these Terms and Conditions
                    and comply with all of these Terms and Conditions during the Competition Period.
                </p>
                <p>3. Draw</p>
                <p>Winner(s) will be drawn on 27 March 2020 (Draw Date) as further set out below.</p>
                <p>4. General Terms and Conditions</p>
                <p>
                    4.1 These Terms and Conditions and all other information provided prior to entering the Competition,
                    including any instructions on how to enter the Competition, form part of these Terms and Conditions.
                    Submission of entry in this Competition is deemed acceptance of these Terms and Conditions. Any
                    entry not complying with these Terms and Conditions is invalid.
                </p>
                <p>
                    4.2 If this Competition is interfered with in any way or is not capable of being conducted as
                    reasonably anticipated due to any reason beyond the reasonable control of the Promoter, the Promoter
                    reserves the right, in its sole discretion, to the fullest extent permitted by law:
                </p>
                <p>(a) to disqualify any Participant; or</p>
                <p>
                    (b) subject to any written directions from a regulatory authority, to modify, suspend, terminate or
                    cancel the Competition, as appropriate.
                </p>
                <p>
                    4.3 Any cost associated with accessing the Hub, the promotional websites and mobile applications is
                    the Participant’s responsibility and is dependent on the Internet service provider used. The use of
                    any automated software or any other mechanical or electronic means that allows a Participant to
                    automatically enter or register for the Competition repeatedly is prohibited and will render all
                    entries submitted by that Participant invalid.
                </p>
                <p>5. Entry Methods and Restrictions</p>
                <p>5.1 Participants</p>
                <p>
                    (a) The Competition is open to Participants up to the age of 18 years old who have purchased and/or
                    legally obtained a ticket to view The Wishmas Tree in cinemas in Australia (Ticket) in accordance
                    with these Terms and Conditions (Participants).
                </p>
                <p>
                    (b) The Promoter reserves the right, at any time, to verify the validity of entries and Participants
                    (including a Participant’s identity, age and place of residence) and to disqualify any Participant
                    who submits an entry that is not in accordance with these Terms and Conditions or who tampers with
                    the entry process. Errors and omissions will be accepted at the Promoter&rsquo;s discretion. Failure
                    by the Promoter to enforce any of its rights at any stage does not constitute a waiver of those
                    rights.
                </p>
                <p>5.2 Registration and Sign Up of Participants</p>
                <p>
                    (a) To enter the Competition, Participants must submit a picture of their Ticket and submit their
                    personal details including club or school name, phone number (not required field), email address and
                    name to the Hub (visitsanctuarycity.com) during the Competition Period but not after the End Date
                    (Sign Up). To enter the Competition, Participants must nominate their club or school with their
                    entry. A Sign Up that does not nominate a club or school will be deemed invalid.
                </p>
                <p>
                    If the Participant is under 18 years old (Child Users), they must provide a parent or guardian name
                    and email address and be supervised by their parents/guardians (Parent Users). Parent Users can
                    enter Child Users into the Competition.
                </p>
                <p>
                    (b) The minimum requirement for the Competition to run is 15 Sign Ups (Minimum Entry Amount). If the
                    Minimum Entry Amount is not met by the Commencement Date then the Competition will immediately end
                    and there will be no School/Club Prize or Individual Prizes awarded.
                </p>
                <p>5.3 General Condition of Entries</p>
                <p>(a) Participants may enter the Competition multiple times using different Tickets.</p>
                <p>
                    (b) Entries must be received by the Promoter during the Competition Period. Automated and/or
                    computer-generated entries will not be accepted.
                </p>
                <p>
                    (c) Entries are deemed to be received at the time of receipt of registration in the Promoter’s
                    database. The Promoter is not responsible for receipt of incorrect, inaccurate or incomplete
                    information caused by a Participant or occurring during transmission. The Promoter is not
                    responsible for any problems or technical malfunction of any telephone, telephone or computer
                    network, or lines, servers, or telephone or internet providers, traffic congestion on any phone or
                    computer network, or any combination thereof, including any injury or damage to Participants or any
                    other person’s handset or computer related to or resulting from participation or sending or
                    receiving of any communication or of any materials in this Competition. The Promoter has no
                    obligation to acknowledge or return the entry.
                </p>
                <p>(d) Incomplete entries will be deemed invalid.</p>
                <p>6. Entry Restrictions</p>
                <p>
                    Employees (and their immediate families) of the Promoter, its related entities, cinema exhibitors
                    and Universal Pictures Australia are ineligible to enter. Immediate family means any of the
                    following: spouse, ex-spouse, de-facto spouse, child or step-child (whether natural or by adoption),
                    parent, step-parent, grandparent, step-grandparent, uncle, aunt, niece, nephew, brother, sister,
                    step-brother, step-sister or 1st cousin.
                </p>
                <p>7. Draw</p>
                <p>
                    7.1 Each valid entry will be entered into an electronic drawing system from which the winner(s) will
                    be drawn at random (Winner).
                </p>
                <p>7.2 The first drawn Participant will be the winner of the School/Club Prize (as defined below).</p>
                <p>
                    7.3 The next 20 drawn Participants will be the winners of the Individual Prize (as defined below).
                </p>
                <p>
                    7.4 The Winner(s) shall be determined at 1821 Ipswich Road, Rocklea in the State of Queensland
                    (Promoter’s Office) and shall be published on the Hub website (visitsanctuarycity.com), announced on
                    the Promoter’s various social media platforms and winner(s) shall be notified by email within 7 days
                    of the Draw Date (Notification Date) The Winner(s) will have 30 days from the Notification Date to
                    accept their Prize (Prize Claim Period).
                </p>
                <p>
                    7.5 The Draw is final and binding upon all Participants and no correspondence will be entered into.
                    Participants who are not winners will not receive any notification at all. The Promoter will contact
                    the Winner(s) in accordance with clause 7.4 above to arrange for delivery of the Prize.
                </p>
                <p>
                    If 7.6 the School/Club Prize or the Individual Prize is unavailable for any reason, the Promoter, in
                    its discretion, reserves the right to substitute the Prize with a gift to the equal value and/or
                    specification, subject to any written directions from a regulatory authority.
                </p>
                <p>8. Prize Details</p>
                <p>8.1 School/Club Prize</p>
                <p>
                    One school/club prize in the amount of $5,000 will be awarded to the first drawn Participant’s
                    nominated school or club (School/Club Prize).
                </p>
                <p>8.2 Individual Prizes</p>
                <p>
                    Twenty (20) individual Universal Movie prize packs will be awarded to Participants drawn second to
                    twenty first with a total maximum value of $35.00 (Individual Prize).
                </p>
                <p>8.3 Any cash prize over $2,000 will be paid via cheque or EFT.</p>
                <p>9. Terms and Conditions of the School/Club Prize or Individual Prize</p>
                <p>
                    9.1 As a condition of accepting the School/Club Prize or the Individual Prize, the Winner (and their
                    parent/guardian) must sign any legal documentation as and in the form required by the Promoter in
                    their absolute discretion, including but not limited to a legal release and indemnity form.
                </p>
                <p>
                    9.2 The School/Club Prize or the Individual Prize must be claimed before the end of the Prize Claim
                    Period.
                </p>
                <p>
                    9.3 The School/Club Prize or the Individual Prize must be taken as stated and no compensation will
                    be payable if a winner is unable to use the prize as stated. The School/Club Prize or the Individual
                    Prize is not transferable to another person (unless agreed to by the Promoter) or exchangeable for
                    other goods and services and cannot be redeemed for cash.
                </p>
                <p>
                    9.4 The Promoter reserves the right to request Winners to provide proof of identity, proof of
                    residency and/or proof of entry validity (for example, a phone bill or store receipt for purchase
                    requirement) in order to claim the School/Club Prize or the Individual Prize. Proof of identity,
                    residency and/or entry validity considered suitable for verification is at the Promoter’s
                    discretion. In the event that a Winner cannot provide suitable proof, the winner will forfeit the
                    prize in whole and no substitute will be offered.
                </p>
                <p>
                    9.5 The Promoter reserves the right to redraw in the event of any Participant being unable to
                    satisfy these Terms and Conditions or forfeiting or not claiming the School/Club Prize or the
                    Individual Prize by the Prize Claim Date and Time. If the School/Club Prize or an Individual Prize
                    remains unclaimed or forfeited through ineligibility or otherwise, the Promoter will conduct a
                    further draw, as required, at the same time and place as the original draw on the day after the
                    Prize Claim Period ends (Subsequent Draw Date). The alternate winner shall be notified in within 7
                    days of the Subsequent Draw Date. The alternate winner will have 30 days from the date they were
                    notified to claim their School/Club Prize or Individual Prize. Any prize not claimed by the
                    alternate winner within 30 days of the date they were notified of their Prize will be forfeited.
                </p>
                <p>
                    9.6 For the avoidance of doubt, if the Winner of the School/Club Prize does not satisfy these Terms
                    and Conditions or forfeits or does not claim the School/Club Prize by the Prize Claim Date and Time,
                    the Promoter will conduct a further draw as outlined in clause 9.6 above. The Participant drawn
                    second in the Draw will not be considered the alternate winner of the School/Club Prize.
                </p>
                <p>
                    9.7 A Participant is not restricted to winning only one prize, if the Participant has submitted
                    multiple valid entries.
                </p>
                <p>10. Privacy</p>
                <p>10.1 You accept the Promoter’s Privacy Policy, found here: visitsanctuarycity.com</p>
                <p>
                    10.2 The Promoter needs to collect the personal information required to be submitted with your entry
                    so it can enter you in the Competition. The Promoter may use personal information about you for
                    related purposes including sending you information (including electronically) about its products,
                    events, news and further competitions. The Promoter may disclose personal information to other
                    organisations that assist it to promote its events and artists. If you wish to access the personal
                    information the Promoter holds about you or do not wish to have this information disclosed, please
                    contact the Promoter at the Promoter’s Office or refer to the Promoter’s Privacy Policy.
                </p>
                <p>11. The Hub</p>
                <p>
                    Use of the Hub is subject to its terms and conditions, which are available here:
                    visitsanctuarycity.com
                </p>
                <p>12. Disclaimers and Limitation of Liability</p>
                <p>
                    12.1 Except for any liability that cannot be excluded by law, the Promoter (including its officers,
                    employees and agents) excludes all liability (including negligence), for any personal injury; or any
                    loss or damage (including loss of opportunity); whether direct, indirect, special or consequential,
                    arising in any way out of the Competition, including, but not limited to, where arising out of the
                    following:
                </p>
                <p>
                    (a) any technical difficulties or equipment malfunction (whether or not under the Promoter’s
                    control);
                </p>
                <p>(b) any theft, unauthorised access or third-party interference;</p>
                <p>
                    (c) any claim or offer that is late, lost, altered, damaged or misdirected (whether or not after
                    their receipt by the Promoter) due to any reason beyond the reasonable control of the Promoter;
                </p>
                <p>(d) any variation in gift value to that stated in these Terms and Conditions;</p>
                <p>(e) any tax liability incurred by a Participant; or</p>
                <p>(f) use of a School/Club Prize or Individual Prize.</p>
                <p>
                    12.2 The Promoter and its associated entities shall not be liable in any way if the performance of
                    obligations hereunder is delayed or becomes impossible by reason of any Act of God, war, fire,
                    earthquake, strike, sickness, accident, civil commotion or any other cause.
                </p>
                <p>12.3 The Promoter is not responsible for any:</p>
                <p>
                    (a) malfunction, delay or traffic congestion on any telephone network or line, computer on-line
                    system, servers or providers, computer equipment, software, or website;
                </p>
                <p>(b) failure of any email or entry to be received by the Promoter; or</p>
                <p>
                    (c) any injury or damage to Participants or any other person related to or resulting from
                    participation in the Competition or downloading any materials in a Competition.
                </p>
                <p>
                    12.4 The Promoter reserves the right in its sole discretion to cancel, terminate, modify or suspend
                    the Competition or disqualify any individual who tampers with the entry process if, for any reason,
                    a Competition does not run for its duration due to a computer virus, tampering, unauthorised
                    intervention, fraud, technical failures or any other cause beyond the control of the Promoter that
                    corrupts or affects the administration, security, fairness, integrity or proper conduct of the
                    Competition.
                </p>
                <p>13. Governing Law</p>
                <p>
                    13.1 These Terms and Conditions shall be governed by the laws of the State in Australia in which you
                    are a resident. You irrevocably submit to the jurisdiction of the courts of that State.
                </p>
                <p>
                    13.2 If any provision of these Terms and Conditions is found to be invalid or unenforceable by a
                    court of law, such invalidity or unenforceability shall not affect the remainder of the Terms and
                    Conditions which shall continue to have full force and effect.
                </p>
                <p>
                    13.3 Our waiver of a breach by you of these Terms and Conditions does not amount to a waiver of all
                    breaches by you, and we reserve our rights pursuant to these Terms and Conditions in respect of any
                    other or further breaches by you of these Terms and Conditions.
                </p>
            </div>
        </div>
    );
};
