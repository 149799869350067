import React, { PropsWithChildren } from "react";

interface IProps {}

export const Header = (props: PropsWithChildren<IProps>) => {
    return (
        <header>
            <div className="title">
                <div id="TFSC" className="titleImage" />
                <div id="TWT" className="titleImage" />
            </div>
        </header>
    );
};
