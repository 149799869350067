import React, { FunctionComponent, PropsWithChildren } from "react";
import Helmet from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

interface IMetaAsName {
    name: string;
    content?: string;
}

interface IMetaAsProperty {
    property: string;
    content?: string;
}

type Meta = (IMetaAsName | IMetaAsProperty)[];

interface IProps {
    description?: string;
    lang: string;
    meta: Meta;
    title: string;
}

export const SEO: FunctionComponent<IProps> = ({ description, lang, meta, title }: PropsWithChildren<IProps>) => {
    const { site } = useStaticQuery(
        graphql`
            query {
                site {
                    siteMetadata {
                        title
                        description
                        author
                    }
                }
            }
        `,
    );

    const metaDescription = `${description || site.siteMetadata.description}`;
    const rootUrl = "https://watchtowin.visitsanctuarycity.com";

    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const shareThumbnailRelative = require("../images/share_thumbnail_1024x1024.jpg");
    const shareThumbnail = `${rootUrl}${shareThumbnailRelative}`;

    const fullMeta: Meta = [
        {
            name: `description`,
            content: metaDescription,
        },
        {
            property: `og:title`,
            content: title,
        },
        {
            property: `og:description`,
            content: metaDescription,
        },
        {
            property: `og:image`,
            content: shareThumbnail,
        },
        {
            property: `og:image:width`,
            content: `1024`,
        },
        {
            property: `og:image:height`,
            content: `1024`,
        },
        {
            property: `og:type`,
            content: `website`,
        },
        {
            property: `og:url`,
            content: rootUrl,
        },
        {
            name: `twitter:card`,
            content: `summary`,
        },
        {
            name: `twitter:creator`,
            content: `${site.siteMetadata.author}`,
        },
        {
            name: `twitter:title`,
            content: title,
        },
        {
            name: `twitter:description`,
            content: metaDescription,
        },
    ];

    const mergedMeta = fullMeta.concat(meta);

    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const favicon = require("../images/favicon.ico");

    return (
        <Helmet
            htmlAttributes={{
                lang,
            }}
            title={title}
            titleTemplate={`%s | ${site.siteMetadata.title}`}
            meta={mergedMeta}
            link={[
                {
                    rel: "shortcut icon",
                    href: favicon,
                    type: "image/ico",
                },
                {
                    rel: `stylesheet`,
                    href: `https://fonts.googleapis.com/css?family=Luckiest+Guy|Yantramanav&display=swap`,
                },
            ]}
        />
    );
};

SEO.defaultProps = {
    lang: `en`,
    meta: [],
    description: ``,
};
